import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../components/Inicio.vue";
import HomeView from "../components/HomeView.vue";
import About from "../components/AboutView.vue";
import Search from "../components/Search.vue";
import SearchHotel from "../components/SearchHotel.vue";
import HotelesDisponibles from "../components/HotelesDisponibles.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/HotelesDisponibles",
    name: "HotelesDisponibles",
    component: HotelesDisponibles,
    props: true,
  },

  {
    path: "/HomeView",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/About",
    name: "About",
    component: About,
  },

  {
    path: "/Search",
    name: "Search",
    component: Search,
  },
  {
    path: "/SearchHotel",
    name: "SearchHotel",
    component: SearchHotel,
    props: true,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
