<template>
  <div class="box">
    <br /><br />
    <div class="VerHoteles" v-if="!roomsCollapse">
      <v-row>
        <v-col>
          <b-switch
            @input="obtenerColumnasMap"
            type="is-success"
            v-model="ViewMap"
            :rounded="false"
            >Ver Mapa</b-switch
          ></v-col
        >
        <v-col>
          <v-card class="overflow-hidden">
            <v-card-text>
              <v-autocomplete
                :items="arrayDeObjetos"
                :filter="customFilter"
                color="white"
                item-text="HotelName"
                label="Nombre del Hotel"
                v-model="selectedItem"
                @input="actualizarvista(selectedItem)"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="ViewMap">
        <v-col>
          <div
            v-for="(hotel, index) in displayedItems"
            :key="index"
            cols="12"
            md="4"
          >
            <v-card :loading="loading">
              <v-row no-gutters>
                <v-col>
                  <v-container>
                    <v-card align="center" justify="center">
                      <v-carousel
                        :show-arrows="false"
                        cycle
                        delimiter-icon="mdi-minus"
                        hide-delimiters
                        height="200"
                        width="290"
                      >
                        <v-carousel-item
                          v-for="(carousel, i) in obtenerArrayImg(hotel.Images)"
                          :key="i"
                        >
                          <v-img
                            height="200"
                            width="290"
                            :src="carousel.image"
                            alt="Cargando Imagen"
                            onerror="this.onerror=null;this.src='https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png'"
                          ></v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col>
                  <v-row>
                    <v-card-title>
                      <v-row>
                        <v-col cols="10"> {{ hotel.HotelName }}&nbsp; </v-col>
                        <v-col cols="1">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="IrMap(hotel.Map)"
                                color="error"
                                fab
                                x-small
                                dark
                              >
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-map-marker</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Ver en el Mapa</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :clearable="false"
                          :value="parseInt(hotel.HotelRating)"
                          color="warning "
                          background-color="primary lighten-1"
                          dense
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">
                          ({{ hotel.HotelRating }})
                        </div>
                      </v-row>
                      <div class="my-4 text-subtitle-1">
                        ${{ obtenertarifa(hotel.Codes) }}
                      </div>
                    </v-card-text>
                  </v-row>
                  <v-card-text>
                    <v-row>
                      <v-chip
                        class="ma-2"
                        color="success"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-timer </v-icon>
                        CheckIn: {{ hotel.CheckInTime }}
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        color="success"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-timer-outline</v-icon>
                        CheckOut: {{ hotel.CheckOutTime }}
                      </v-chip>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-dialog
                      width="300px"
                      v-model="dialogOpened[hotel.Codes]"
                      :key="hotel.Codes"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="openDialog(hotel.Codes)"
                        >
                          Facilities
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Facilities</span>
                        </v-card-title>
                        <v-card-text>
                          <ul>
                            <li
                              v-for="(item, index) in ObtenerFacilities(
                                hotel.HotelFacilities
                              )"
                              :key="index"
                            >
                              {{ item }}
                            </li>
                          </ul>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="green darken-1"
                            text
                            @click="closeDialog()"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="verRooms(hotel.Codes, hotel.HotelName)"
                    >
                      <span>Ver Habitaciones</span>
                      <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-row align="center" justify="space-around">
                  <v-btn icon @click="descrip(hotel.Codes)" tile>
                    <v-icon left>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                    Descripción
                  </v-btn>
                </v-row>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show[hotel.Codes]" :key="hotel.Codes">
                  <v-divider></v-divider>

                  <v-card-text>
                    <div class="my-4 text-subtitle-1">Descripción</div>
                    <p v-html="hotel.Description" style="font-size: 10pt"></p>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
            <br />
          </div>
        </v-col>

        <v-col v-if="hotelObject">
          <div
            class="map-container"
            style="width: 100%; height: 500px"
            v-if="showMap"
          >
            <GmapMap
              :center="center"
              :zoom="zoom"
              style="width: 100%; height: 100%"
              ref="map"
            >
              <GmapMarker
                v-for="(marker, index) in markers"
                :key="index"
                :position="marker.position"
                :options="{ icon: require('../assets/hotel.png') }"
                v-on:mouseover="showInfoWindow(marker)"
                :clickable="true"
                @click="addInfoWindow(marker, index)"
              />
              <GmapInfoWindow
                ref="infoWindow"
                :opened="infoWindowOpened"
                :position="infoWindowPosition"
              >
                <div style="text-align: center">
                  <b-tag type="is-danger is-light" icon="map-marker">
                    <strong>{{ hotelObject.name }}</strong>
                  </b-tag>
                  <br />
                  <b-tag icon="cash" type="is-success is-light">{{
                    hotelObject.price
                  }}</b-tag>
                  <b-rate
                    v-if="hotelObject.rating"
                    :value="hotelObject.rating"
                    disabled
                  />
                  <a @click="verRooms(hotelObject.code, hotelObject.name)">
                    <b-tag icon="arrow-right" type="is-info is-light">ir</b-tag>
                  </a>
                </div>
              </GmapInfoWindow>
            </GmapMap>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div v-for="(hotel, i) in firstHalf" :key="i">
            <v-card :loading="loading">
              <v-row no-gutters>
                <v-col>
                  <v-container>
                    <v-card align="center" justify="center">
                      <v-carousel
                        :show-arrows="false"
                        cycle
                        delimiter-icon="mdi-minus"
                        hide-delimiters
                        height="200"
                        width="290"
                      >
                        <v-carousel-item
                          v-for="(carousel, i) in obtenerArrayImg(hotel.Images)"
                          :key="i"
                        >
                          <v-img
                            height="200"
                            width="290"
                            :src="carousel.image"
                            alt="Cargando Imagen"
                            onerror="this.onerror=null;this.src='https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png'"
                          ></v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col>
                  <v-row>
                    <v-card-title>
                      <v-row>
                        <v-col cols="10"> {{ hotel.HotelName }}&nbsp; </v-col>
                        <v-col cols="1">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="IrMap(hotel.Map)"
                                color="error"
                                fab
                                x-small
                                dark
                              >
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-map-marker</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Ver en el Mapa</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :clearable="false"
                          :value="parseInt(hotel.HotelRating)"
                          color="warning "
                          background-color="primary lighten-1"
                          dense
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">
                          ({{ hotel.HotelRating }})
                        </div>
                      </v-row>
                      <div class="my-4 text-subtitle-1">
                        ${{ obtenertarifa(hotel.Codes) }}
                      </div>
                    </v-card-text>
                  </v-row>
                  <v-card-text>
                    <v-row>
                      <v-chip
                        class="ma-2"
                        color="success"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-timer </v-icon>
                        CheckIn: {{ hotel.CheckInTime }}
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        color="success"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-timer-outline</v-icon>
                        CheckOut: {{ hotel.CheckOutTime }}
                      </v-chip>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-dialog
                      width="300px"
                      v-model="dialogOpened[hotel.Codes]"
                      :key="hotel.Codes"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="openDialog(hotel.Codes)"
                        >
                          Facilities
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Facilities</span>
                        </v-card-title>
                        <v-card-text>
                          <ul>
                            <li
                              v-for="(item, index) in ObtenerFacilities(
                                hotel.HotelFacilities
                              )"
                              :key="index"
                            >
                              {{ item }}
                            </li>
                          </ul>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="green darken-1"
                            text
                            @click="closeDialog()"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="verRooms(hotel.Codes, hotel.HotelName)"
                    >
                      <span>Ver Habitaciones</span>
                      <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-row align="center" justify="space-around">
                  <v-btn icon @click="descrip(hotel.Codes)" tile>
                    <v-icon left>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                    Descripción
                  </v-btn>
                </v-row>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show[hotel.Codes]" :key="hotel.Codes">
                  <v-divider></v-divider>

                  <v-card-text>
                    <div class="my-4 text-subtitle-1">Descripción</div>
                    <p v-html="hotel.Description" style="font-size: 10pt"></p>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
            <br />
          </div>
        </v-col>

        <v-col>
          <div v-for="(hotel, i) in secondHalf" :key="i">
            <v-card :loading="loading">
              <v-row no-gutters>
                <v-col>
                  <v-container>
                    <v-card align="center" justify="center">
                      <v-carousel
                        :show-arrows="false"
                        cycle
                        delimiter-icon="mdi-minus"
                        hide-delimiters
                        height="200"
                        width="280"
                      >
                        <v-carousel-item
                          v-for="(carousel, i) in obtenerArrayImg(hotel.Images)"
                          :key="i"
                        >
                          <v-img
                            height="200"
                            width="280"
                            :src="carousel.image"
                            alt="Cargando Imagen"
                            onerror="this.onerror=null;this.src='https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png'"
                          ></v-img>
                        </v-carousel-item>
                      </v-carousel>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col>
                  <v-row>
                    <v-card-title>
                      <v-row>
                        <v-col cols="10"> {{ hotel.HotelName }}&nbsp; </v-col>
                        <v-col cols="1">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="IrMap(hotel.Map)"
                                color="error"
                                fab
                                x-small
                                dark
                              >
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-map-marker</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Ver en el Mapa</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-row align="center" class="mx-0">
                        <v-rating
                          :clearable="false"
                          :value="parseInt(hotel.HotelRating)"
                          color="warning "
                          background-color="primary lighten-1"
                          dense
                          readonly
                          size="14"
                        ></v-rating>

                        <div class="grey--text ms-4">
                          ({{ hotel.HotelRating }})
                        </div>
                      </v-row>
                      <div class="my-4 text-subtitle-1">
                        ${{ obtenertarifa(hotel.Codes) }}
                      </div>
                    </v-card-text>
                  </v-row>
                  <v-card-text>
                    <v-row>
                      <v-chip
                        class="ma-2"
                        color="success"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-timer </v-icon>
                        CheckIn: {{ hotel.CheckInTime }}
                      </v-chip>
                      <v-chip
                        class="ma-2"
                        color="success"
                        label
                        text-color="white"
                      >
                        <v-icon left> mdi-timer-outline</v-icon>
                        CheckOut: {{ hotel.CheckOutTime }}
                      </v-chip>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-dialog
                      width="300px"
                      v-model="dialogOpened[hotel.Codes]"
                      :key="hotel.Codes"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="openDialog(hotel.Codes)"
                        >
                          Facilities
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Facilities</span>
                        </v-card-title>
                        <v-card-text>
                          <ul>
                            <li
                              v-for="(item, index) in ObtenerFacilities(
                                hotel.HotelFacilities
                              )"
                              :key="index"
                            >
                              {{ item }}
                            </li>
                          </ul>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="closeDialog()"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="verRooms(hotel.Codes, hotel.HotelName)"
                    >
                      <span>Ver Habitaciones</span>
                      <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-row align="center" justify="space-around">
                  <v-btn icon @click="descrip(hotel.Codes)" tile>
                    <v-icon left>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                    Descripción
                  </v-btn>
                </v-row>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show[hotel.Codes]" :key="hotel.Codes">
                  <v-divider></v-divider>

                  <v-card-text>
                    <div class="my-4 text-subtitle-1">Descripción</div>
                    <p v-html="hotel.Description" style="font-size: 10pt"></p>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
            <br />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="ViewMap">
        <v-pagination
          class="text-center"
          v-model="paginaActual"
          :length="totalPaginas"
          :total-visible="7"
          circle
        ></v-pagination>
      </v-row>
    </div>

    <v-dialog
      v-model="roomsCollapse"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
            icon
            dark
            @click="
              Rooms = undefined;
              roomsCollapse = false;
              e1 = 1;
            "
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Regresar</v-toolbar-title>
        </v-toolbar>
        <div
          class="VerRooms"
          v-if="roomsCollapse && isCardModalActive == false"
        >
          <div class="notification">
            <div class="content">
              <div
                class="box"
                style="display: flex; flex-wrap: wrap; justify-content: center"
              >
                <div
                  style="margin: 5px"
                  class="card"
                  v-for="(item, index) in Rooms.Rooms"
                  :key="index"
                >
                  <div>
                    Suplementos &nbsp;
                    <b-icon
                      :icon="
                        obtenerSuplementos(arrayDeHoteles[index].Rooms)
                          ? 'check-circle'
                          : 'alpha-x-circle'
                      "
                      type="is-primary"
                    ></b-icon>
                    <div v-if="obtenerSuplementos(arrayDeHoteles[index].Rooms)">
                      <p>
                        Type:
                        {{
                          arrayDeHoteles[index].Rooms[0].Supplements[0][0].Type
                        }}
                        <br />
                        Description:
                        {{
                          arrayDeHoteles[index].Rooms[0].Supplements[0][0]
                            .Description
                        }}
                        <br />
                        Price:
                        {{
                          arrayDeHoteles[index].Rooms[0].Supplements[0][0].Price
                        }}
                        {{
                          arrayDeHoteles[index].Rooms[0].Supplements[0][0]
                            .Currency
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="header">
                    <span class="title"> {{ item.Name[0] }}</span>
                    <span class="price"
                      >${{ item.TotalFare }} {{ Rooms.Currency }}</span
                    >
                  </div>
                  <p class="desc">{{ item.Inclusion }}</p>
                  <ul class="lists">
                    <li class="list">
                      <b-icon
                        :icon="
                          item.IsRefundable === 'true'
                            ? 'check-circle'
                            : 'alpha-x-circle'
                        "
                        type="is-primary"
                      >
                      </b-icon>
                      <span>&nbsp;Reembolsable</span>
                    </li>

                    <li class="list">
                      {{ item.RoomPromotion[0] }}
                    </li>
                  </ul>

                  <b-button
                    type="is-primary"
                    label="Ver Detalles"
                    expanded
                    rounded
                    @click="Prebook(Rooms.Rooms[index])"
                  />
                </div>
                <v-dialog v-model="loading" hide-overlay persistent width="300">
                  <v-card color="primary" dark>
                    <v-card-text>
                      Please stand by
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="Prebook" v-if="isCardModalActive">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Amenities
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">
                Rate Conditions
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" step="3">
                Politicas de cancelación
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 4" step="4"
                >Formulario de Habitaciónes</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step step="5"> Reservación </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-text>
                        <tbody
                          v-if="objPrebook.HotelResult[0].Rooms[0].Amenities"
                        >
                          <tr
                            v-for="(Amenitie, index) in objPrebook
                              .HotelResult[0].Rooms[0].Amenities"
                            :key="index"
                          >
                            <td>{{ index + 1 }} -</td>
                            <td>{{ Amenitie }}</td>
                          </tr>
                        </tbody>
                        <h1 v-else>unspecified Amenities</h1>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="
                            isCardModalActive = false;
                            e1 = 1;
                          "
                        >
                          Regresar
                        </v-btn>
                        <v-btn color="primary" @click="e1 = 2">
                          Continue
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" id="columnNo">No.</th>
                            <th class="text-left" id="columnDescription">
                              Description
                            </th>
                          </tr>
                        </thead>

                        <tbody
                          v-if="
                            objPrebook &&
                            objPrebook.HotelResult &&
                            objPrebook.HotelResult[0] &&
                            objPrebook.HotelResult[0].RateConditions
                          "
                        >
                          <tr
                            v-for="(condition, index) in objPrebook
                              .HotelResult[0].RateConditions"
                            :key="index"
                          >
                            <td>{{ index + 1 }}</td>
                            <td v-html="decodeHTML(condition)"></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="e1 = 1"> Regresar </v-btn>
                    <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card>
                  <v-card-text>
                    <table>
                      <caption>
                        Cancel Policies
                      </caption>
                      <thead>
                        <tr>
                          <th>Cancellation date</th>
                          <th>&nbsp;|&nbsp;</th>
                          <th>Charge Type</th>
                          <th>&nbsp;|&nbsp;</th>
                          <th>Cancellation Charge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(dia, i) in objPrebook.HotelResult[0].Rooms[0]
                            .CancelPolicies"
                          :key="i"
                        >
                          <td>{{ formatDate(dia.FromDate) }}</td>
                          <td>&nbsp;&nbsp;</td>
                          <td>{{ dia.ChargeType }}</td>
                          <td>&nbsp;&nbsp;</td>
                          <td>{{ dia.CancellationCharge }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="e1 = 2">Regresar</v-btn>
                    <v-btn color="primary" @click="e1 = 4">Continue</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="4">
                <v-card>
                  <v-card-text>
                    <div
                      v-for="(habitacion, j) in habitacionesData"
                      :key="'habitacion' + j"
                    >
                      Habitación {{ habitacion.id }} <br />
                      <div
                        v-for="(adulto, i) in habitacionesData[j].adultosData"
                        :key="'adulto' + i"
                      >
                        Adulto: {{ adulto.id }}
                        <v-row>
                          <v-col>
                            Género
                            <v-radio-group v-model="adulto.Title" row>
                              <v-radio label="Hombre" value="Mr"></v-radio>
                              <v-radio
                                label="Mujer"
                                value="Ms"
                                v-if="adulto.Title == 'Ms'"
                              ></v-radio>
                              <v-radio
                                label="Mujer"
                                value="Mrs"
                                v-else
                              ></v-radio>
                            </v-radio-group>
                            <v-radio-group
                              v-if="
                                adulto.Title == 'Ms' || adulto.Title == 'Mrs'
                              "
                              v-model="adulto.Title"
                              row
                            >
                              <v-radio label="Soltera" value="Ms"></v-radio>
                              <v-radio label="Casada" value="Mrs"></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="FirstName"
                              v-model="adulto.FirstName"
                              :rules="[
                                rules.noCharSpecials,
                                rules.required,
                                rules.noEmptyFields,
                                rules.nombreRules,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="LastName"
                              v-model="adulto.LastName"
                              :rules="[
                                rules.noCharSpecials,
                                rules.required,
                                rules.noEmptyFields,
                                rules.nombreRules,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        v-for="(ninio, i) in habitacionesData[j].niniosData"
                        :key="'ninio' + i"
                      >
                        Niño: {{ ninio.id }}

                        <v-row>
                          <v-col>
                            Género
                            <v-radio-group v-model="ninio.Title" row>
                              <v-radio label="Niño" value="Mr"></v-radio>
                              <v-radio label="Niña" value="Ms"></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="FirstName"
                              v-model="ninio.FirstName"
                              :rules="[
                                rules.noCharSpecials,
                                rules.required,
                                rules.noEmptyFields,
                                rules.nombreRules,
                              ]"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="LastName"
                              v-model="ninio.LastName"
                              :rules="[
                                rules.noCharSpecials,
                                rules.required,
                                rules.noEmptyFields,
                                rules.nombreRules,
                              ]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn @click="e1 = 3"> Regresar </v-btn>
                    <v-btn
                      color="primary"
                      @click="Validar(objPrebook.HotelResult[0].Rooms[0])"
                    >
                      Reservar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="5">
                <p v-if="resp && resp.Status">
                  Estatus: {{ resp.Status.Description }} <br />Localizador:
                  {{ bookData.BookingReferenceId }}
                </p>

                <v-card :loading="loading">
                  <div v-if="bookContainer">
                    <v-container>
                      <v-col>
                        cargando detalles...
                        <v-skeleton-loader
                          v-bind="attrs"
                          type="article, actions"
                        >
                        </v-skeleton-loader>
                      </v-col>
                    </v-container>
                  </div>
                  <div v-else>
                    <v-card-title>Detalles de la reserva</v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th id="Des">Concepto</th>
                              <th id="value">Descripción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                value, key
                              ) in bookingDetail.BookingDetail"
                              :key="key"
                            >
                              <td>{{ key }}</td>
                              <td v-html="displayValue(value)"></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </div>

                  <v-dialog
                    v-model="loading"
                    hide-overlay
                    persistent
                    width="300"
                  >
                    <v-card color="primary" dark>
                      <v-card-text>
                        Please stand by
                        <v-progress-linear
                          indeterminate
                          color="white"
                          class="mb-0"
                        ></v-progress-linear>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="teal" @click="BookingDetails()">
                      Ver Detalles
                    </v-btn>
                    <v-btn color="error" @click="Cancelar"> Cancelar </v-btn>
                    <v-btn color="primary" @click="roomsCollapse = false">
                      Cerrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HotelesService from "@/services/HotelesService";

export default {
  props: ["arrayDeHoteles", "habitacionDescrip"],
  data() {
    return {
      infoWindowOpened: true,
      bookContainer: false,
      rules: {
        nombreRules: (value) =>
          (value && value.length >= 3 && value.length <= 25) ||
          "El nombre del hotel debe tener entre 3 y 25 caracteres",

        required: (value) => !!value || "Campo requerido",
        noCharSpecials: (value) => {
          const specialCharactersRegex = /[!@#_$%^&*=(),.?":{}|<>¡]/;
          return (
            !specialCharactersRegex.test(value) ||
            "No se permiten caracteres especiales."
          );
        },
        // Nueva regla para validar campos vacíos
        noEmptyFields: (value) => !!value || "Este campo no puede estar vacío",
      },
      respBook: {},
      e1: 1,
      showMap: false,
      loading: false,
      show: {},
      activeStep: 0,
      adultosData: [],
      niniosData: [],
      habitacionesData: [],
      arrayDeObjetos: [],
      markers: [],
      roomsCollapse: false,
      center: { lat: 0, lng: 0 },
      objPrebook: {},
      zoom: 10,
      infoWindowPosition: null,
      hotelObject: {},
      cancelar: [],
      isCardModalActive: false,
      bookingDetail: [],
      bookData: {},
      ///////////////////
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      firstHalf: [],
      secondHalf: [],
      ViewMap: true,
      CustomerDetails: [],
      resp: {},
      dialogOpened: [],
      items: [
        {
          action: "mdi-label",
          title: "List item 1",
        },
        {
          divider: true,
        },
        {
          action: "mdi-label",
          title: "List item 2",
        },
        {
          divider: true,
        },
        {
          action: "mdi-label",
          title: "List item 3",
        },
      ],
      itemsPorPagina: 3,
      paginaActual: 1,
      name: "",
      resultadosBusqueda: [],
      hasSaved: false,
      selectedItem: null,
      model: null,
      indice: 0,
      states: [
        { name: "Florida", abbr: "FL", id: 1 },
        { name: "Georgia", abbr: "GA", id: 2 },
        { name: "Nebraska", abbr: "NE", id: 3 },
        { name: "California", abbr: "CA", id: 4 },
        { name: "New York", abbr: "NY", id: 5 },
      ],
    };
  },

  mounted() {
    this.ordenarArrayHoteles();
    this.$nextTick(() => {
      if (this.$refs.map) {
        this.$refs.map.$mapPromise.then(() => {
          // El mapa se ha inicializado correctamente, puedes hacer cualquier inicialización adicional aquí si es necesario
          console.log("Mapa inicializado");
        });
      }
    });
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.firstHalf.length / this.itemsPorPagina);
    },
    displayedItems() {
      const inicio = (this.paginaActual - 1) * this.itemsPorPagina;
      const fin = inicio + this.itemsPorPagina;
      return this.firstHalf.slice(inicio, fin);
    },
    isTitleSelected() {
      return this.adulto.Title !== ""; // Comprueba si se ha seleccionado algún título
    },
  },

  methods: {
    obtenerSuplementos(value) {
      for (let item of value) {
        if (item.Supplements) {
          return true;
        }
      }
    },
    displayValue(value) {
      // Verificar si es un objeto
      if (typeof value === "object") {
        // Si es un objeto, comprobamos si es un array o un objeto normal
        if (Array.isArray(value)) {
          // Si es un arreglo, devolvemos un párrafo para cada elemento
          return value
            .map((item) => `<p>${this.displayValue(item)}</p>`)
            .join("");
        } else {
          // Si es un objeto, creamos una lista de atributos y valores
          return `<ul>${Object.entries(value)
            .map(
              ([key, val]) =>
                `<li><strong>${key}</strong>: ${this.displayValue(val)}</li>`
            )
            .join("")}</ul>`;
        }
      } else {
        // Si no es un objeto, decodificamos el HTML
        return this.decodeHTML(value);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.HotelName.toLowerCase();
      const textTwo = item.Address.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    // Función para normalizar un nombre completo
    normalizarNombre(nombreCompleto) {
      return nombreCompleto.toLowerCase().replace(/\s+/g, "");
    },

    // Función para contar nombres repetidos
    contarNombresRepetidos(datos, nombresRepetidos) {
      let hayNombresRepetidos = false;

      for (const dato of datos) {
        const nombreCompleto = this.normalizarNombre(
          dato.FirstName + " " + dato.LastName
        );
        if (nombresRepetidos[nombreCompleto]) {
          nombresRepetidos[nombreCompleto]++;
          hayNombresRepetidos = true;
        } else {
          nombresRepetidos[nombreCompleto] = 1;
        }
      }

      return hayNombresRepetidos;
    },

    // Función para imprimir nombres repetidos
    imprimirNombresRepetidos(nombresRepetidos) {
      for (const nombre in nombresRepetidos) {
        if (nombresRepetidos[nombre] > 1) {
          this.$buefy.toast.open({
            message: `${nombre} se repite ${nombresRepetidos[nombre]} veces.`,
            type: "is-danger",
          });
        }
      }
    },

    actualizarvista(name) {
      this.obtenerdatosH(name);
      if (this.selectedItem == null || this.selectedItem == "") {
        this.obtenerColumnasMap();
      } else {
        this.firstHalf = this.resultadosBusqueda;
      }
    },
    obtenerdatosH(name) {
      let filteredResults = false; // Inicializar la variable fuera de la asignación

      if (name != null) {
        filteredResults = this.arrayDeObjetos.filter((item) => {
          // Verificar si item y name no son null o undefined
          if (item && item.HotelName && name) {
            return item.HotelName.toLowerCase().includes(name.toLowerCase());
          }
          return false; // Si item, item.HotelName o name es null o undefined, retornar falso
        });
      }

      this.resultadosBusqueda = filteredResults; // Asignar el resultado filtrado a resultadosBusqueda

      return filteredResults !== false; // Retornar verdadero si hay resultados filtrados, de lo contrario falso
    },
    Validar(objPrebook) {
      if (!this.validarCampos()) {
        return;
      }

      const nombresRepetidos = {};
      const hayNombresRepetidos =
        this.validarNombresRepetidos(nombresRepetidos);

      if (hayNombresRepetidos) {
        this.imprimirNombresRepetidos(nombresRepetidos);
      } else {
        this.book(objPrebook);
        // Continuar si no hay nombres repetidos y no hay campos vacíos
        // Aquí puedes colocar el código para avanzar hacia otro lado
      }
    },

    validarCampos() {
      for (const habitacion of this.habitacionesData) {
        for (const adulto of habitacion.adultosData) {
          if (!adulto.FirstName || !adulto.LastName || !adulto.Title) {
            this.mostrarMensaje("Falta datos de un adulto.");
            return false;
          }
        }
        for (const ninio of habitacion.niniosData) {
          if (!ninio.FirstName || !ninio.LastName) {
            this.mostrarMensaje("Falta datos de un niño.");
            return false;
          }
        }
      }
      return true;
    },

    validarNombresRepetidos(nombresRepetidos) {
      let hayNombresRepetidos = false;

      for (const habitacion of this.habitacionesData) {
        const adultosRepetidos = this.contarNombresRepetidos(
          habitacion.adultosData,
          nombresRepetidos
        );
        const niniosRepetidos = this.contarNombresRepetidos(
          habitacion.niniosData,
          nombresRepetidos
        );

        hayNombresRepetidos =
          hayNombresRepetidos || adultosRepetidos || niniosRepetidos;
      }

      return hayNombresRepetidos;
    },

    formatDate(date) {
      // Quitar la hora, los minutos y los segundos de la cadena
      const fechaString = date.slice(0, 10);
      return fechaString;
    },

    async book(objPrebook) {
      this.loading = true;

      this.bookData = {};
      let fechaActual = new Date();
      // Obtener la marca de tiempo Unix (en milisegundos)
      let tiempoUnix = fechaActual.getTime();
      this.bookData = {
        BookingCode: objPrebook.BookingCode,
        CustomerDetails: this.habitacionesData,
        ClientReferenceId: "2022040402",
        BookingReferenceId: tiempoUnix.toString(),
        TotalFare: objPrebook.TotalFare,
        EmailId: "it@contravel.com.mx",
        PhoneNumber: "5568188424",
        BookingType: "Voucher",
        PaymentMode: "Limit",
      };

      this.resp = await HotelesService.book(this.bookData);
      if (this.resp.Status.Description == "Successful") {
        this.$buefy.toast.open({
          message: "Se ha realizado la reservación con éxito",
          type: "is-success",
        });
      } else {
        this.$buefy.toast.open({
          message: this.resp.Status,
          type: "is-danger",
        });
      }
      this.BookingDetails();
      this.e1 = 5;
      this.loading = false;
    },
    async BookingDetails() {
      this.bookContainer = true;
      await new Promise((resolve) => {
        setTimeout(async () => {
          this.bookingDetail = await HotelesService.bookingDetail(
            this.bookData.BookingReferenceId
          );
          this.guardarBookin();
          resolve();
        }, 120000); // 5000 milisegundos son 5 segundos
      });
    },

    async guardarBookin() {
      this.respBook = await HotelesService.guardarBookin(
        this.bookingDetail,
        this.bookData
      );
      this.bookContainer = false;
    },
    async Cancelar() {
      this.bookContainer = true;
      this.cancelar = await HotelesService.Cancelar(
        this.respBook.CVE_RESERVACION
      );

      this.BookingDetails();
      this.$buefy.toast.open({
        message: this.cancelar.Description,
        type: "is-success",
      });
    },
    decodeHTML(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText;
    },

    openDialog(index) {
      // Abre el diálogo correspondiente y cierra los demás
      this.dialogOpened = this.dialogOpened.map((_, i) => i === index);
    },
    closeDialog() {
      // Cierra el diálogo correspondiente

      this.dialogOpened = [];
    },
    descrip(index) {
      this.$set(this.show, index, !this.show[index]);
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },

    crearArray() {
      this.habitacionesData = [];
      this.adultosData = [];
      this.niniosData = [];
      let index = 0;
      for (const habitacion of this.habitacionDescrip) {
        this.adultosData = []; // Reinicializa el array en cada iteración
        this.niniosData = []; // Reinicializa el array en cada iteración

        for (let i = 0; i < habitacion.adultos; i++) {
          let adultoObject = {
            id: i + 1,
            Title: null,
            FirstName: null,
            LastName: null,
            Type: "Adult",
          };
          this.adultosData.push(adultoObject);
        }

        if (habitacion.ninoslenght > 0) {
          for (let j = 0; j < habitacion.ninoslenght; j++) {
            let ninioObject = {
              id: j + 1,
              Title: null,
              FirstName: null,
              LastName: null,
              Type: "Child",
            };
            this.niniosData.push(ninioObject);
          }
        }

        this.habitacionesData.push({
          id: index + 1,
          adultosData: this.adultosData,
          niniosData: this.niniosData,
        });

        index = index + 1;
      }

      this.isCardModalActive = true;
    },
    async Prebook(item) {
      this.loading = true;

      this.objPrebook = {};
      let status = "";
      let metodo = "Limit";
      this.objPrebook = await HotelesService.Prebook(item.BookingCode, metodo);

      status = this.objPrebook.Status.Description;

      this.isCardModalActive = true;
      this.loading = false;
      if (status == "Successful") {
        this.crearArray();
      } else {
        this.$buefy.toast.open({
          message: status,
          type: "is-danger",
        });

        this.$router.push({
          name: "Inicio",
        });
      }
    },
    verRooms(code, name) {
      this.loading = true;
      this.Rooms = [];
      for (const hotelLeido of this.arrayDeHoteles) {
        if (hotelLeido.HotelCode == code) {
          this.Rooms = hotelLeido;
          this.Rooms.name = name;
        }
      }
      this.roomsCollapse = true;
      this.loading = false;
    },

    obtenertarifa(codigoHotel) {
      for (const hotelLeido of this.arrayDeHoteles) {
        if (hotelLeido.HotelCode == codigoHotel) {
          return hotelLeido.Rooms[0].TotalFare + " " + hotelLeido.Currency;
        }
      }
    },

    IrMap(coordenada) {
      this.ViewMap = true;
      let centerString = new Object();
      const coords = coordenada.split("|");
      centerString.lat = parseFloat(coords[0]);
      centerString.lng = parseFloat(coords[1]);
      this.center = centerString;
      this.zoom = 16;
    },
    showInfoWindow(marker) {
      this.infoWindowPosition = marker.position;
      for (const objectDetails of this.arrayDeObjetos) {
        if (objectDetails.Codes == marker.code) {
          const info = new Object();
          info.name = objectDetails.HotelName;
          info.price = this.obtenertarifa(objectDetails.Codes);
          info.rating = parseInt(objectDetails.HotelRating);
          info.code = marker.code;
          this.hotelObject = info;
        }
      }
    },

    addInfoWindow(marker) {
      this.infoWindowPosition = marker.position;

      // Verificar si la referencia infoWindow está definida y es un objeto
      if (
        this.$refs.infoWindow &&
        typeof this.$refs.infoWindow.open === "function"
      ) {
        // Abrir la ventana de información
        this.$refs.infoWindow.opened = true;
      } else {
        console.error(
          "Error: La referencia infoWindow no está definida o no es válida"
        );
      }
    },
    async cargarMapa() {
      try {
        this.markers = [];
        for (const elemento of this.arrayDeObjetos) {
          const hoteles = new Object();
          const centerString = this.obtenerLocationMap2(elemento.Map);
          this.center = centerString;
          hoteles.code = elemento.Codes;
          hoteles.position = centerString;
          this.markers.push(hoteles);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.obtenerColumnasMap();
      }
    },

    obtenerColumnasMap() {
      if (this.ViewMap) {
        this.firstHalf = this.arrayDeObjetos;
      } else {
        const halfLength = Math.ceil(this.arrayDeObjetos.length / 2);
        this.firstHalf = this.arrayDeObjetos.slice(0, halfLength);
        this.secondHalf = this.arrayDeObjetos.slice(halfLength);
      }
    },
    obtenerLocationMap2(row) {
      let centerString = new Object();
      const coords = row.split("|");
      centerString.lat = parseFloat(coords[0]);
      centerString.lng = parseFloat(coords[1]);
      return centerString;
    },

    ObtenerFacilities(row) {
      const amenitiesList = [];

      let obj = JSON.parse(row);

      const arr = Object.values(obj);

      for (let i = 0; i < arr.length; i++) {
        // Quitar las comillas al inicio y al final de cada elemento
        amenitiesList[i] = arr[i].replace(/^"|"$/g, "");
        // Agrupamos "^" y "$" con las comillas para explicitar la precedencia
      }

      return amenitiesList;
    },

    obtenerArrayImg(row) {
      let ImagesObject = new Object();
      let ArrayImages = [];
      if (row == "null" || row == "undefined") {
        ImagesObject = new Object();
        ImagesObject.id = 1;
        ImagesObject.image =
          "https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png";
        ArrayImages.push(ImagesObject);
      } else {
        try {
          let obj = JSON.parse(row);
          const arr = Object.values(obj);
          ArrayImages = [];
          ImagesObject = new Object();
          if (arr.length > 0) {
            for (let index = 0; index < arr.length; index++) {
              ImagesObject = new Object();
              ImagesObject.id = index + 1;
              ImagesObject.image = arr[index];
              ArrayImages.push(ImagesObject);
            }
          } else {
            ImagesObject = new Object();
            ImagesObject.id = 1;
            ImagesObject.image =
              "https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png";
            ArrayImages.push(ImagesObject);
          }
        } catch (error) {
          console.log(error.message);
        }
      }

      return ArrayImages;
    },
    obtenerLocationMap(row) {
      let centerString = new Object();
      const coords = row.split("|");
      centerString.lat = parseFloat(coords[0]);
      centerString.lng = parseFloat(coords[1]);
      return centerString;
    },

    ordenarArrayHoteles() {
      if (this.arrayDeHoteles && this.arrayDeHoteles.length > 0) {
        // Copiar el valor de arrayDeHoteles a una variable local
        let arrayDeHotelesCopy = JSON.parse(
          JSON.stringify(this.arrayDeHoteles)
        );

        // 1. Ordenar el array de habitaciones en cada hotel por precio
        arrayDeHotelesCopy.forEach((hotel) => {
          hotel.Rooms.sort((a, b) => a.TotalFare - b.TotalFare);
        });

        // 2. Ordenar el array de hoteles por la habitación más económica
        arrayDeHotelesCopy.sort(
          (a, b) => a.Rooms[0].TotalFare - b.Rooms[0].TotalFare
        );

        const arrayCodes = arrayDeHotelesCopy.map((code) => code.HotelCode);
        this.obtenerDetallesHoteles(arrayCodes);
        this.showMap = true;
      } else {
        this.$router.push({
          name: "Inicio",
        });
      }
    },

    async obtenerDetallesHoteles(arrayCodes) {
      this.arrayDeObjetos = await HotelesService.obtenerDetallesHoteles(
        arrayCodes
      );

      this.cargarMapa();
    },
  },
};
</script>

<style scoped>
.mi-imagen {
  max-width: 100%;
}

@media (min-width: 768px) {
  .mi-imagen {
    max-width: 270px;
  }
}

@media (max-width: 767px) {
  .mi-imagen {
    max-width: 225px;
  }
}

.scroll-container {
  height: 25vh;
  /* Altura fija del contenedor */
  width: 25vw;
  /* Ancho fijo del contenedor */
  overflow: auto;
  /* Añade la barra de desplazamiento */
}

.card {
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 2rem;
  width: 320px;
  flex-direction: column;
  border-radius: 0.25rem;
  background-color: rgba(17, 24, 39, 1);
  padding: 1.5rem;
}

.header {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #fff;
}

.price {
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  color: #fff;
}

.desc {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.625;
  color: rgba(156, 163, 175, 1);
}

.lists {
  margin-bottom: 1.5rem;
  flex: 1 1 0%;
  color: rgba(156, 163, 175, 1);
}

.lists .list {
  margin-bottom: 0.5rem;
  display: flex;
  margin-left: 0.5rem;
}

.lists .list svg {
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  color: rgba(167, 139, 250, 1);
}
</style>
