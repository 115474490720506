<template>
  <section>
    <b-switch
      @input="obtenerColumnasMap"
      type="is-success"
      v-model="ViewMap"
      :rounded="false"
      >Ver Mapa</b-switch
    >
    <div class="columns">
      <div class="column">
        <b-table
          :data="isEmpty ? [] : firstHalf"
          bordered
          striped
          narrowed
          hoverable
          focusable
          mobile-cards
          paginated
          per-page="10"
        >
          <b-table-column label="Galeria" v-slot="props">
            <div class="rows">
              <div class="row justify-content-center">
                <b-carousel :indicator="false">
                  <b-carousel-item
                    v-for="(carousel, i) in obtenerArrayImg(props.row.Images)"
                    :key="i"
                  >
                    <figure>
                      <p class="image" @error="handleImageError">
                        <img
                          :src="carousel.image"
                          class="mi-imagen"
                          style="height: 100px"
                          alt="Carrusel"
                        />
                      </p>
                    </figure>
                  </b-carousel-item>
                </b-carousel>
              </div>
              <div
                class="row"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p class="control" v-if="props.row.HotelRating">
                  <b-rate
                    :value="parseInt(props.row.HotelRating)"
                    show-score
                    disabled
                  />
                </p>
              </div>
            </div>
          </b-table-column>
          <b-table-column
            label="Ubicación"
            v-slot="props"
            width="5vw"
            height="5vh"
          >
            <h3 class="subtitle">
              <strong>{{ props.row.HotelName }}</strong>
            </h3>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <b-collapse :open="false" aria-id="contentIdForA11y1">
                      <template #trigger="props">
                        <b-button
                          label="Descripción"
                          type="is-primary"
                          aria-controls="contentIdForA11y1"
                          :aria-expanded="props.open"
                          :icon-left="props.open ? 'menu-down' : 'menu-up'"
                        />
                      </template>
                      <div class="notification">
                        <div class="content">
                          <div class="scroll-container">
                            <p
                              v-html="props.row.Description"
                              style="font-size: 10pt"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="row">
                    <b-collapse :open="false" aria-id="contentIdForA11y1">
                      <template #trigger="props">
                        <b-button
                          label="Facilidades"
                          type="is-warning"
                          aria-controls="contentIdForA11y1"
                          :aria-expanded="props.open"
                          :icon-left="props.open ? 'menu-down' : 'menu-up'"
                        />
                      </template>
                      <div class="notification">
                        <div class="content">
                          <div class="scroll-container">
                            <p
                              v-html="
                                ObtenerFacilities(props.row.HotelFacilities)
                              "
                              style="font-size: 10pt"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
              <div class="column">
                <b-field>
                  <b-tag type="is-success is-light" icon="whatsapp">{{
                    props.row.PhoneNumber
                  }}</b-tag>
                </b-field>

                <b-field grouped>
                  <b-field label="CheckIn">
                    <b-tag type="is-info is-light" icon="timer">{{
                      props.row.CheckInTime
                    }}</b-tag>
                  </b-field>
                  <b-field label="CheckOut">
                    <b-tag type="is-info is-light" icon="timer-outline">{{
                      props.row.CheckOutTime
                    }}</b-tag>
                  </b-field></b-field
                >
              </div>
            </div>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>
        </b-table>
      </div>

      <div class="column" v-if="ViewMap">
        <GmapMap
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 700px"
        >
          <GmapMarker
            v-for="(marker, index) in markers"
            :key="index"
            :position="marker.position"
            :options="{ icon: require('../assets/hotel.png') }"
            v-on:mouseover="showInfoWindow(index)"
            v-on:mouseout="hideInfoWindow"
            @click="addInfoWindow(marker, index)"
          />
          <GmapInfoWindow
            :opened="infoWindowOpened"
            :position="infoWindowPosition"
          >
            <div>
              <strong
                ><h3>{{ infoWindowName }}</h3></strong
              >
              <p>{{ infoWindowContent }}</p>
              <p>Price: {{ infoWindowPrice }}</p>
              <p class="control" v-if="infoWindowRating">
                <b-rate :value="infoWindowRating" show-score disabled />
              </p>
            </div>
          </GmapInfoWindow>
        </GmapMap>
      </div>

      <div class="column" v-else>
        <b-table
          :data="isEmpty ? [] : secondHalf"
          bordered
          striped
          narrowed
          hoverable
          focusable
          mobile-cards
          paginated
          per-page="10"
        >
          <b-table-column label="Galeria" v-slot="props">
            <div class="rows">
              <div class="row justify-content-center">
                <b-carousel :indicator="false">
                  <b-carousel-item
                    v-for="(carousel, i) in obtenerArrayImg(props.row.Images)"
                    :key="i"
                  >
                    <figure>
                      <p class="image" @error="handleImageError">
                        <img
                          :src="carousel.image"
                          class="mi-imagen"
                          style="height: 100px"
                          alt="disponible"
                        />
                      </p>
                    </figure>
                  </b-carousel-item>
                </b-carousel>
              </div>
              <div
                class="row"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p class="control" v-if="props.row.HotelRating">
                  <b-rate
                    :value="parseInt(props.row.HotelRating)"
                    show-score
                    disabled
                  />
                </p>
              </div>
            </div>
          </b-table-column>
          <b-table-column
            label="Ubicación"
            v-slot="props"
            width="5vw"
            height="5vh"
          >
            <h3 class="subtitle">
              <strong>{{ props.row.HotelName }}</strong>
            </h3>

            <div class="columns">
              <div class="column">
                <div class="rows">
                  <div class="row">
                    <b-collapse :open="false" aria-id="contentIdForA11y1">
                      <template #trigger="props">
                        <b-button
                          label="Descripción"
                          type="is-primary"
                          aria-controls="contentIdForA11y1"
                          :aria-expanded="props.open"
                          :icon-left="props.open ? 'menu-down' : 'menu-up'"
                        />
                      </template>
                      <div class="notification">
                        <div class="content">
                          <div class="scroll-container">
                            <p
                              v-html="props.row.Description"
                              style="font-size: 10pt"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="row">
                    <b-collapse :open="false" aria-id="contentIdForA11y1">
                      <template #trigger="props">
                        <b-button
                          label="Facilidades"
                          type="is-warning"
                          aria-controls="contentIdForA11y1"
                          :aria-expanded="props.open"
                          :icon-left="props.open ? 'menu-down' : 'menu-up'"
                        />
                      </template>
                      <div class="notification">
                        <div class="content">
                          <div class="scroll-container">
                            <p
                              v-html="
                                ObtenerFacilities(props.row.HotelFacilities)
                              "
                              style="font-size: 10pt"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
              <div class="column">
                <b-field>
                  <b-tag type="is-success is-light" icon="whatsapp">{{
                    props.row.PhoneNumber
                  }}</b-tag>
                </b-field>
                <b-field grouped>
                  <b-field label="CheckIn">
                    <b-tag type="is-info is-light" icon="timer">{{
                      props.row.CheckInTime
                    }}</b-tag>
                  </b-field>
                  <b-field label="CheckOut">
                    <b-tag type="is-info is-light" icon="timer-outline">{{
                      props.row.CheckOutTime
                    }}</b-tag>
                  </b-field></b-field
                >
              </div>
            </div>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapComponent",

  props: {
    miArray: Array,
  },
  data() {
    return {
      isEmpty: false,
      center: { lat: 40.712775, lng: -74.005973 },
      zoom: 14,
      markers: [],
      infoWindowOpened: false,
      infoWindowPosition: null,
      infoWindowName: null,
      infoWindowContent: null,
      infoWindowPrice: null,
      infoWindowRating: null,

      ///////////////////

      firstHalf: [],
      secondHalf: [],
      ViewMap: true,
      mapOptions: {
        styles: [
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 100 }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#d3d3d3" }, { lightness: 100 }],
          },
          // Agrega aquí más elementos del estilo
        ],
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        gestureHandling: "cooperative",
        contador: 0,
      },
    };
  },

  mounted() {
    this.cargarMapa();
  },
  methods: {
    handleImageError(event) {
      event.target.src =
        "https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png";
    },
    showInfoWindow(index) {
      const marker = this.markers[index];
      this.infoWindowPosition = marker.position;
      this.infoWindowName = marker.name;
      this.infoWindowContent = marker.content;
      this.infoWindowPrice = marker.price;
      this.infoWindowRating = marker.rating;
      this.infoWindowOpened = true;
    },
    hideInfoWindow() {
      this.infoWindowOpened = false;
    },
    addInfoWindow(marker, index) {
      console.log(marker, index);
    },
    async cargarMapa() {
      try {
        this.markers = [];
        let longitud = Object.keys(this.miArray).length;
        console.log(this.miArray);
        const TAMANO_PEDAZO = 25000; // Dividir en pedazos de 5000 elementos cada uno
        for (let index = 0; index < longitud; index += TAMANO_PEDAZO) {
          const pedazo = this.miArray.slice(index, index + TAMANO_PEDAZO);
          for (const elemento of pedazo) {
            const hoteles = new Object();
            const centerString = this.obtenerLocationMap2(elemento.Map);
            this.center = centerString;
            hoteles.position = centerString;
            hoteles.icon = undefined;
            hoteles.name = elemento.HotelName;
            hoteles.content = undefined;
            hoteles.price = undefined;
            hoteles.rating = parseInt(elemento.HotelRating);
            this.markers.push(hoteles);
          }
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.obtenerColumnasMap();
      }
    },

    obtenerColumnasMap() {
      if (this.ViewMap) {
        this.firstHalf = this.miArray;
      } else {
        const halfLength = Math.ceil(this.miArray.length / 2);
        this.firstHalf = this.miArray.slice(0, halfLength);
        this.secondHalf = this.miArray.slice(halfLength);
      }
    },
    obtenerLocationMap2(row) {
      let centerString = new Object();
      const coords = row.split("|");
      centerString.lat = parseFloat(coords[0]);
      centerString.lng = parseFloat(coords[1]);
      return centerString;
    },

    ObtenerFacilities(row) {
      let amenitiesList = "<ul>\n";
      if (row === "null" || row === "undefined") {
        amenitiesList += `<li>No hay datos</li>\n`;
      } else {
        let obj = JSON.parse(row);
        const arr = Object.values(obj);
        console.log(arr);
        for (const item of arr) {
          amenitiesList += `<li>${item}</li>\n`;
        }
      }
      amenitiesList += "</ul>";
      return amenitiesList;
    },

    obtenerArrayImg(row) {
      let ImagesObject = new Object();
      let ArrayImages = [];
      if (row == "null" || row == "undefined") {
        ImagesObject = new Object();
        ImagesObject.id = 1;
        ImagesObject.image =
          "https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png";
        ArrayImages.push(ImagesObject);
      } else {
        try {
          let obj = JSON.parse(row);
          const arr = Object.values(obj);
          ArrayImages = [];
          ImagesObject = new Object();
          if (arr.length > 0) {
            for (let index = 0; index < arr.length; index++) {
              ImagesObject = new Object();
              ImagesObject.id = index + 1;
              ImagesObject.image = arr[index];
              ArrayImages.push(ImagesObject);
            }
          } else {
            ImagesObject = new Object();
            ImagesObject.id = 1;
            ImagesObject.image =
              "https://josefacchin.com/wp-content/uploads/2018/09/http-not-found-error-404.png";
            ArrayImages.push(ImagesObject);
          }
        } catch (error) {
          console.log(error.message);
        }
      }

      return ArrayImages;
    },
    obtenerLocationMap(row) {
      let centerString = new Object();
      const coords = row.split("|");
      centerString.lat = parseFloat(coords[0]);
      centerString.lng = parseFloat(coords[1]);
      return centerString;
    },
  },
};
</script>

<style>
.mi-imagen {
  max-width: 100%;
}
@media (min-width: 768px) {
  .mi-imagen {
    max-width: 270px;
  }
}

@media (max-width: 767px) {
  .mi-imagen {
    max-width: 225px;
  }
}

.scroll-container {
  height: 25vh; /* Altura fija del contenedor */
  width: 25vw; /* Ancho fijo del contenedor */
  overflow: auto; /* Añade la barra de desplazamiento */
}
</style>
